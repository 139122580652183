import React from "react";
import { MonitorSVG } from "../../icons/monitor";
import "../../css/sakura-ink.css";
import "../../css/cards.css";
import { Link } from "react-router-dom";

export const HomePage = () => {
  return (
    <>
      <h1>Amazon SiteConnect</h1>
      <p>
        SiteConnect is a VPN remote access solution to enable vendors to
        configure and monitor onsite equipment at externalized sites remotely.
      </p>
      <p>
        If you have any questions about the Siteconnect process{" "}
        <b>reach out to your Amazon point of contact.</b>
      </p>
      <h2>Install</h2>
      Follow the platform specific instructions
      <br />
      <div className="wrapper">
        <div className="card">
          <MonitorSVG />
          <h4>
            <Link to="/windows/install.html">Windows</Link>
          </h4>
        </div>
      </div>
    </>
  );
};
