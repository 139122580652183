import { returnPosturePayload } from "@amzn/siteconnect-idp-posture-assessment/dist/lib/network";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PostureResult } from "../../../common/models";
import { copyClipboard, getPostureResult } from "../../../common/utils";
import {
  Alert,
  Button,
  FlashbarProps,
  Input,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import Collapsible from "react-collapsible"; // https://github.com/glennflanagan/react-collapsible#readme
import "../../../css/sakura-ink.css";
import "../../../css/cards.css";
import { ACCOUNT_MAPPINGS } from "../../../common/constants";

interface PostureTypeResult {
  reason?: string;
  result?: string;
}

interface TroubleshootingPageProps {
  createFlashbarMessage: (content: string, type: FlashbarProps.Type) => void;
}

const chooseResult = (
  postureResult: string | undefined
): string | undefined => {
  if (postureResult === undefined) {
    return "success";
  } else if (postureResult === "undetermined") {
    return undefined;
  }
  return "fail";
};

export const TroubleshootingPage = ({
  createFlashbarMessage,
}: TroubleshootingPageProps) => {
  const codeText =
    'Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process -Force\n& "C:\\Program Files (x86)\\Amazon\\SiteConnect\\configure-siteconnect.ps1" install';

  const powershellLogCode = `Get-ChildItem "$($env:USERPROFILE)\\AppData\\Roaming\\AWSVPNClient\\logs" | Sort-Object -Property CreationTime | Where-Object { $_.FullName -like "*ovpn_aws_vpn_client*"} | Select-Object -Last 1 FullName`;

  const [postureResult, setPostureResult] = useState<PostureResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<string>();

  const [postureHeader, setPostureHeader] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [supportedOS, setSupportedOS] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [OSType, setOSType] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [firewall, setFirewall] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [antivirus, setAntivirus] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });
  const [structure, setStructure] = useState<PostureTypeResult>({
    reason: undefined,
    result: undefined,
  });

  useEffect(() => {
    const getPayload = async () => {
      setLoading(true);
      const payload = JSON.stringify(await returnPosturePayload());
      getPostureResult(payload, userId)
        .then((r) => {
          setPostureResult(r.data);
        })
        .catch((err) => {
          createFlashbarMessage(
            `There was an issue assessing posture: ${
              err.message
            }. Unable to complete request to https://${ACCOUNT_MAPPINGS.get(
              window?.location.hostname
            )}`,
            "error"
          );
          setPostureResult({
            result: false,
            reason: {
              "os.type": "undetermined",
              "os.version": "undetermined",
              antivirus: "undetermined",
              firewall: "undetermined",
              structure: "undetermined",
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (loading) {
      getPayload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, userId]);

  useEffect(() => {
    const createErrorText = (failureReason: string | undefined) => {
      if (failureReason === undefined) {
        return undefined;
      }
      return "- " + failureReason;
    };

    if (!loading) {
      let postureText;
      let structureText = postureResult?.reason.structure;
      if (postureResult?.result) {
        postureText = "Suceeded!";
      } else if (postureResult?.reason.structure === 'undetermined') {
        postureText = "Failed! Unable to determine posture. See flashbar error message for more info."
      } else {
        postureText = "Failed! Please fix the issue(s) below.";
      }

      setPostureHeader({
        result: postureResult?.result ? "success" : "fail",
        reason: createErrorText(postureText),
      });
      if (
        structureText !== undefined &&
        structureText.includes("Missing SiteConnect Web Server")
      ) {
        // we want to use a better formatted text for missing web server errors
        let configureText =
          "Please fix your local web server. If not installed, follow instructions in the install page. If already installed, follow instructions in the updating page.";
          setStructure({
            result: chooseResult(postureResult?.reason.structure),
            reason: createErrorText(configureText),
          });
          setSupportedOS({
            result: chooseResult(postureResult?.reason["os.version"]),
            reason: createErrorText(configureText),
          });
          setOSType({
            result: chooseResult(postureResult?.reason["os.type"]),
            reason: createErrorText(configureText),
          });
          setFirewall({
            result: chooseResult(postureResult?.reason.firewall),
            reason: createErrorText(configureText),
          });
          setAntivirus({
            result: chooseResult(postureResult?.reason.antivirus),
            reason: createErrorText(configureText),
          });
          return;
        }
        setStructure({
          result: chooseResult(postureResult?.reason.structure),
          reason: createErrorText(structureText),
        });
        setSupportedOS({
          result: chooseResult(postureResult?.reason["os.version"]),
          reason: createErrorText(postureResult?.reason["os.version"]),
        });
        setOSType({
          result: chooseResult(postureResult?.reason["os.type"]),
          reason: createErrorText(postureResult?.reason["os.type"]),
        });
        setFirewall({
          result: chooseResult(postureResult?.reason.firewall),
          reason: createErrorText(postureResult?.reason.firewall),
        });
        setAntivirus({
          result: chooseResult(postureResult?.reason.antivirus),
          reason: createErrorText(postureResult?.reason.antivirus),
        });
    } else {
      setPostureHeader({ result: undefined, reason: undefined });
      setStructure({ result: undefined, reason: undefined });
      setSupportedOS({ result: undefined, reason: undefined });
      setOSType({ result: undefined, reason: undefined });
      setFirewall({ result: undefined, reason: undefined });
      setAntivirus({ result: undefined, reason: undefined });
    }
  }, [postureResult, loading]);

  return (
    <body>
      <h1>Windows Troubleshooting</h1>

      <h2>SiteConnect Connection Issues</h2>
      <SpaceBetween direction="vertical" size="xs">
        <h3 className={postureHeader.result}>
          Your Posture Status {postureHeader.reason}
        </h3>
        <div>
          Please note that all sections should be{" "}
          <text style={{ color: "green" }}> green</text>.{" "}
          <b>
            This is required or your connection will be unsuccessful. You need
            to have the most recent version of the web server installed. If you
            do not or are unsure, go back to{" "}
            <Link to="/windows/install.html">the install page</Link> and follow
            the install instructions.
          </b>
        </div>
        {postureResult?.result === false && (
          <>
            <Alert type="info">
              If you need help debugging posture issues, you may enter your
              email before checking posture to help our team identify your
              posture. Note that this is an optional field and you are not
              required to enter your email to check posture.
            </Alert>
            <Input
              value={userId ?? ""}
              placeholder="Enter Email Address and click the button below"
              onChange={({ detail }) => {
                setUserId(detail.value);
              }}
              data-id="linkInputField"
            />
          </>
        )}
        <Button
          variant="primary"
          onClick={() => setLoading(true)}
          loading={loading}
        >
          Check Posture Again
        </Button>
        <ul>
          <li className={structure.result}>
            <b>SiteConnect Web Server Configured {structure.reason}</b>
          </li>
          <Collapsible
            trigger={"Click to show details"}
            triggerWhenOpen={"Click to hide details"}
            open={structure.result === "fail"}
          >
            <p>
              If you have followed the{" "}
              <Link to="/windows/install.html">Windows Install</Link> then you
              should be able to open&nbsp;
              <Link
                to="http://127.0.0.1:32182"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://127.0.0.1:32182
              </Link>
              &nbsp;and view the posture payload that is returned to
              SiteConnect. If the browser refuses to connect, then the web
              server is <b>not</b> installed correctly.
            </p>

            <p>
              The easiest solution is to just re-run the install script in
              PowerShell as an Administrator which will correctly configure your
              system:
              <pre>
                <code className="code-block">{codeText}</code>
              </pre>
              <button
                className="copy-clipboard"
                type="button"
                onClick={copyClipboard}
              >
                Copy Code
              </button>
              <br />
              If this script fails then you must send the following log file to
              your Amazon representative for further troubleshooting:
              <code>
                %USERPROFILE%\AppData\Local\SiteConnect\configure-siteconnect.log
              </code>
            </p>
          </Collapsible>
          <li id="os-edition-not-allowed" className={OSType.result}>
            <b>Valid Operating System {OSType.reason}</b>
          </li>
          <Collapsible
            trigger={"Click to show details"}
            triggerWhenOpen={"Click to hide details"}
            open={OSType.result === "fail"}
          >
            SiteConnect will only allow Windows editions to connect which
            currently recieve support and security updates.
          </Collapsible>
          <li id="supported-os" className={supportedOS.result}>
            <b>Compliant Operating System version {supportedOS.reason}</b>
          </li>
          <Collapsible
            trigger={"Click to show details"}
            triggerWhenOpen={"Click to hide details"}
            open={supportedOS.result === "fail"}
          >
            Ensure your Operating System and version are supported:
            <table>
              <thead>
                <th>Operating System</th>
                <th>Version</th>
                <th>Edition</th>
                <th>Supported until</th>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={5}>Windows 10</td>
                  <td rowSpan={1}>1809</td>
                  <td>Enterprise LTSC</td>
                  <td>January 9, 2029</td>
                </tr>
                <tr>
                  <td rowSpan={2}>21H2</td>
                  <td>Enterprise</td>
                  <td>June 11, 2024</td>
                </tr>
                <tr>
                  <td>Enterprise LTSC</td>
                  <td>January 12, 2027</td>
                </tr>
                <tr>
                  <td rowSpan={2}>22H2</td>
                  <td>Professional</td>
                  <td rowSpan={2}>October 14, 2025</td>
                </tr>
                <tr>
                  <td>Enterprise</td>
                </tr>
                <tr>
                  <td rowSpan={7}>Windows 11</td>
                  <td rowSpan={1}>21H2</td>
                  <td>Enterprise</td>
                  <td>October 8, 2024</td>
                </tr>
                <tr>
                  <td rowSpan={3}>22H2</td>
                  <td>Home</td>
                  <td rowSpan={2}>October 8, 2024</td>
                </tr>
                <tr>
                  <td>Professional</td>
                </tr>
                <tr>
                  <td>Enterprise</td>
                  <td>October 14, 2025</td>
                </tr>
                <tr>
                  <td rowSpan={3}>23H2</td>
                  <td>Home</td>
                  <td rowSpan={2}>November 11, 2025</td>
                </tr>
                <tr>
                  <td>Professional</td>
                </tr>
                <tr>
                  <td>Enterprise</td>
                  <td>November 10, 2026</td>
                </tr>
              </tbody>
            </table>
          </Collapsible>
          <li className={firewall.result}>
            <b>Firewall {firewall.reason}</b>
          </li>
          <Collapsible
            trigger={"Click to show details"}
            triggerWhenOpen={"Click to hide details"}
            open={firewall.result === "fail"}
          >
            SiteConnect will detect the included OS firewall and most other
            firewall products which Windows is able to detect.
            <br />
            At least one firewall must be detected and enabled.
          </Collapsible>
          <li className={antivirus.result}>
            <b>Antivirus {antivirus.reason}</b>
          </li>
          <Collapsible
            trigger={"Click to show details"}
            triggerWhenOpen={"Click to hide details"}
            open={antivirus.result === "fail"}
          >
            SiteConnect will detect the included OS antivirus and most other
            antivirus products which Windows is able to detect.
            <br />
            At least one antivirus must be detected and enabled.
          </Collapsible>
          <h3>Authorization Issues</h3>
          <li id="user-not-authorized">
            <b>User Not Authorized</b>
          </li>
          <p>
            If you&apos;ve validated that you have all necessary components
            installed:
            <ol>
              <li>AWS VPN Client</li>
              <li>SiteConnect Web Server</li>
            </ol>
            and you are still rejected during a VPN connection attempt, then you
            are either using expired credentials, or an invalid OVPN file.
            Contact your Amazon representative for an updated pair of
            credentials and OVPN file.
            <br />
            ⚠️{" "}
            <b>
              The credentials expire 30 minutes after creation, and last 8 hours
              after initial use.
            </b>
          </p>
        </ul>
        <h2>AWS VPN Client Connection Issues</h2>
        Please ensure you are using the latest version of the AWS VPN Client.
        See&nbsp;
        <Link to="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/client-vpn-connect-windows.html#client-vpn-connect-windows-release-notes">
          AWS VPN Client Release Notes.
        </Link>
        <br />
        See the&nbsp;
        <Link to="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/windows-troubleshooting.html">
          AWS VPN Client Windows Troubleshooting docs
        </Link>
        &nbsp;for specific problems related to the AWS VPN Client.
        <h2>Providing Logs</h2>
        <p>
          To help assist with troubleshooting, you may be asked to provide the
          logs from your client connection.
        </p>
        <p>Follow the steps below to find the correct log file to provide:</p>
        <h3>Windows</h3>
        <ol>
          <li>Run Powershell</li>
          <li>
            Run the following code which returns the file path to the log file
            for your latest VPN connections:
            <pre>
              <code className="code-block">{powershellLogCode}</code>
            </pre>
          </li>
          <li>Use this file and provide it to your Amazon POC when asked.</li>
        </ol>
        <h2>Common Fixes</h2>
        <h3>Resetting TAP adapters</h3>
        <p>
          Some users have experienced problems with connecting to SiteConnect
          due to the AWS VPN Client failing to cleanup TAP adapters. Follow
          these steps to fix such a problem:
        </p>
        <ol>
          <li>Uninstall AWS VPN client</li>
          <li>In device manager, delete all TAP adapters manually</li>
          <li>Re-install AWS VPN client</li>
        </ol>
        <h3>Supported VPN Client Versions</h3>
        <p>
          Check the{" "}
          <Link
            to="https://docs.aws.amazon.com/vpn/latest/clientvpn-user/client-vpn-connect-windows-release-notes.html"
            target="_blank"
          >
            Release Notes
          </Link>{" "}
          page for the minimum version you can use for the AWS VPN Client.
        </p>
        <h3>AWS VPN Client says the port is already in use</h3>
        <p>
          This is a known bug with the AWS VPN Client. Restarting the computer
          will fix this issue.
        </p>
      </SpaceBetween>
    </body>
  );
};
