export const MonitorSVG = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    width={"80%"}
  >
    <defs></defs>
    <title>x</title>
    <path
      className="cls-1"
      d="M119.93405,7.625H8.06595a5.646,5.646,0,0,0-5.55912,4.70525V93.53014a4.51389,4.51389,0,0,0,4.53957,4.3H53.68847l-.09043,3.6052a4.06956,4.06956,0,0,1-1.2809,2.7904l-5.45487,5.119a5.12215,5.12215,0,0,1-3.50509,1.38708h0A6.10922,6.10922,0,0,0,37.248,116.841v3.534H90.752v-3.534a6.10922,6.10922,0,0,0-6.10922-6.10922h0a5.12216,5.12216,0,0,1-3.50509-1.38708l-5.45487-5.119a4.06956,4.06956,0,0,1-1.2809-2.7904l-.09043-3.6052h46.64208a4.51389,4.51389,0,0,0,4.53957-4.3V12.33025A5.646,5.646,0,0,0,119.93405,7.625Zm-4.537,71.92959H12.603V17.74408H115.397Z"
    />
  </svg>
);
