import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { HomePage } from "./home";
import { Header } from "./navigation";
import { InstallPage } from "./windows/install";
import { PrerequisitesPage } from "./pre-requisites";
import { ConnectionPage } from "./windows/connecting";
import { UpdatingPage } from "./windows/updating";
import { TroubleshootingPage } from "./windows/troubleshooting";
import { FAQPage } from "./windows/faq";
import { Scroll, useFlashbarMessages } from "../common/utils";
import { Flashbar } from "@amzn/awsui-components-react";

const App = () => {
  const [flashbarMessages, createFlashbarMessage] = useFlashbarMessages();

  return (
    <Router>
      <Flashbar items={flashbarMessages} />
      <Header />
      <Scroll />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/vpn-connection-prerequisites.html"
          element={<PrerequisitesPage />}
        />
        <Route path="/windows/install.html" element={<InstallPage />} />
        <Route path="/windows/vpn-connect.html" element={<ConnectionPage />} />
        <Route path="/windows/updating.html" element={<UpdatingPage />} />
        <Route
          path="/windows/troubleshooting.html"
          element={
            <TroubleshootingPage
              createFlashbarMessage={createFlashbarMessage}
            />
          }
        />
        <Route path="/windows/faq.html" element={<FAQPage />} />
      </Routes>
    </Router>
  );
};

export default App;
