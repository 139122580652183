// add dev website hostname to WebsiteHostName then include api domain in ACCOUNT_MAPPINGS

enum WebsiteHostName {
  PROD = "instructions.siteconnect.opstech.a2z.com",
  BETA = "instructions-beta.siteconnect.opstech.a2z.com",
  DEV_TINGCHAR = "instructions-tingchar-dev.siteconnect.opstech.a2z.com",
}

export const ACCOUNT_MAPPINGS = new Map<string, string>([]);
ACCOUNT_MAPPINGS.set(
  WebsiteHostName.PROD,
  "instructions.api.siteconnect.opstech.a2z.com"
);
ACCOUNT_MAPPINGS.set(
  WebsiteHostName.BETA,
  "instructions-beta.api.siteconnect.opstech.a2z.com"
);
ACCOUNT_MAPPINGS.set(
  WebsiteHostName.DEV_TINGCHAR,
  "instructions-tingchar-dev.api.siteconnect.opstech.a2z.com"
);
