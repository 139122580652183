import "@amzn/awsui-global-styles/polaris.css";
import React from "react";
import ReactDOM from "react-dom";
import { mockApi } from "./common/utils";
import App from "./components/App";

if (process.env.REACT_APP_ENV_VAR === "mock") {
  mockApi();
}

document.body.className = "awsui-visual-refresh";
// ReactDOM.render is deprecated
// eslint-disable-next-line
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
